<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <ProformasList
            :headers="headersProformas"
            :headers_extra="headersProformasExtras"
            :items="itemsProformas"
            :items_extra="itemsProformasExtras"
            :items_extra_new="itemsProformasExtrasNew"
            @initialize="initialize"
            :search_tool="true"
          ></ProformasList>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ProformasList from "@/views/pages/proformas/ProformasList.vue";
import genericService from "./../../../services/generic.service";
import { mapGetters } from "vuex";

export default {
  components: {
    ProformasList,
  },

  data: () => ({
    uri: "proformas",
    headersProformas: [
      { text: "ID", value: "id", sortable: true, filterable: true },
      { text: "WO", value: "work_order", sortable: false, filterable: true },
      { text: "Vessel", value: "vessel", sortable: false, filterable: true },
      { text: "Port", value: "port", sortable: false, filterable: true },
      { text: "To", value: "to", sortable: false, filterable: true },
      { text: "C/O", value: "co", sortable: false, filterable: true },
      { text: "Date", value: "date", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headersProformasExtras: [
      { text: "ID", value: "id", sortable: true, filterable: true },
      { text: "Description", value: "description", width: '35%',  sortable: false },
      { text: "Place", value: "place", width: '15%', sortable: false },
      { text: "QTY", value: "qty", sortable: false },
      { text: "Unit Price", value: "unit_price", sortable: false },
      { text: "Discount", value: "discount", sortable: false },
      { text: "Subtotal", value: "subtotal", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    itemsProformas: [],
    itemsProformasExtras: [],
    itemsProformasExtrasNew: [],
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      var _self = this;

      if(this.currentUser.role == "Admin") {
        genericService.getRecordListWithLimit('/'+this.uri, 10, "date", _self.currentUser.role,_self.currentUser.id,function(records){
            _self.itemsProformas = records
        });
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Proformas" }]);
  },
  
  computed: {
    ...mapGetters([ 'currentUser' ])
  },

};
</script>
