<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    item-key="id"
    show-expand
    sort-by="date"
    sort-desc
    class="elevation-1"
    @current-items="currentItems"
    :loading="loading"
    loading-text="Loading... Please wait"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Proformas List</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-text-field
          v-if="search_tool"
          v-model="search"
          ref="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>

        <!-- BOM Filtros -->
        <v-dialog v-if="search_tool" v-model="dialogFilters" max-width="600px">
          <template v-slot:activator="{ on }">
            <v-btn
              class="ml-4"
              color="blue-grey"
              fab
              small
              dark
              v-on="on"
              @click="dialogFilters = true"
            >
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Advanced Search</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6" sm="6">
                    <v-menu
                      ref="rangeCalendar"
                      v-model="rangeCalendar"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-x
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="dateRangeText"
                          label="Dates"
                          hint="Enter a date range range. Separate with '~'"
                          persistent-hint
                          prepend-icon="event"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="rangeDates" no-title range>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="rangeDates = []">Clear</v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.rangeCalendar.save(rangeDates);"
                        >OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <v-text-field
                    v-model="filterWorkOrder"
                      label="Work Order"
                      single-line
                      hide-details
                      clearable
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="clearFilterItems()">Clear Filters</v-btn>
              <v-btn color="blue darken-1" text @click="dialogFilters = false;">Close</v-btn>
              <v-btn color="blue darken-1" text @click="filterItems()">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- EOM Filtros -->

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="950px">
          <template v-slot:activator="{ on }">
            <v-btn 
              color="primary"
              dark class="mb-2"
              v-on="on"
              @click="formNewItem()"
            >New Proforma</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row v-if="formNewtItem" class="form-first-field">
                  <v-col cols="12" sm="12">
                    <v-autocomplete
                      prepend-icon="folder"
                      placeholder="Select Work Order"
                      :items="workOrdersList"
                      item-text="id"
                      item-value="id"
                      label="Work Order"
                      v-model="current_wo_id"
                      @change="getWOData()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      prepend-icon="folder"
                      v-model="current_wo_vessel"
                      label="Vessel"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      prepend-icon="folder"
                      v-model="current_wo_port"
                      label="Port"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-menu
                      v-model="popDatepick1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editedItem.date"
                          label="Date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.date"
                        @input="popDatepick1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-textarea
                      v-model="editedItem.co"
                      autocomplete="Care Of"
                      label="C/O"
                      rows=5
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-textarea
                      v-model="editedItem.to"
                      autocomplete="To"
                      label="To"
                      rows=5
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-textarea
                      v-model="editedItem.notes"
                      autocomplete="Notes"
                      label="Notes"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="save(items)"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="seeItem(item)"
        v-b-tooltip.hover
        title="Print"
      >mdi-printer</v-icon>
      <v-icon
        small
        class="mr-2"
        @click="editItem(item, 'dialog')"
        v-b-tooltip.hover
        title="Edit"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item,items)"
        v-b-tooltip.hover
        title="Delete"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="reset">Reset</v-btn>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <div class="row justify-center">
          <div class="col-md-11">
            <div class="row mt-3">
              <div class="col-12">
                <v-container fluid>
                  <h5>Notes:</h5>
                  <p class="mb-0">
                    {{ item.notes }}
                  </p>
                </v-container>
              </div>
            </div>
          </div>
          <div class="col-md-11 mb-6">
            <ProformasLineList
              :headers="headers_extra"
              :items="items_extra"
              :search="search"
              :hide-default-footer="true"
              :current_proforma_id="item.id"
              item-key="id"
              sort-by="id"
              class="elevation-1 transparent-table mb-6 w-100"
            ></ProformasLineList>
          </div>
        </div>
      </td>
    </template>
    <template slot="footer">
      <table>
        <tr
          class="border border-right-0 border-left-0 border-bottom-0 text-center"
        >
          <td colspan="">
            <v-chip color="lighten-1 mr-4">
              Items: &nbsp;
              <strong class="ml-2">{{ itemsFiltered }}</strong>
            </v-chip>
          </td>
        </tr>
      </table>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import genericService from "./../../../services/generic.service";
import ProformasLineList from "@/views/pages/proformas/ProformasLineList.vue";
import Swal from "sweetalert2";

export default {
  data: () => ({
    uri:"proformas",
    workOrdersList: [],
    today: new Date().toISOString().substr(0, 10),
    formNewtItem: true,
    dialog: false,
    dialog2: false,
    dialog3: false,
    dialog4: false,
    search: "",
    expanded: [],
    singleExpand: false,
    popDatepick1: false,
    editedIndex: -1,
    editedIndex2: -1,
    editedItem: {
      id: "",
      work_order: null,
      date: new Date().toISOString().substr(0, 10),
      to: "",
      co: "",
      port: "",
      description: ""
      },
    defaultItem: {
      id: "",
      work_order: null,
      date: new Date().toISOString().substr(0, 10),
      to: "",
      co: "",
      port: "",
      description: ""
    },
    switch1: true,
    switch2: false,
    switch3: true,

    /* Filtros & Loading */
    loading: true,
    filters: [
      { text: "All", value: 99 },
      { text: "ID", value: 0 },
      { text: "Work Order", value: 1 },
      { text: "Vessel", value: 2 },
      { text: "Port", value: 3 },
      { text: "To", value: 4 },
      { text: "C/O", value: 5 },
      { text: "Notes", value: 7 }
    ],
    date1: "",
    date2: "",
    formatedDates: "",
    rangeDates: [],
    rangeCalendar: false,
    dialogFilters: false,
    itemsFiltered: 0,
    totalAmount: 0,
    filterWorkOrder: ""
  }),

  components: {
    ProformasLineList
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Proforma" : "Edit Proforma";
    },
    formTitle2() {
      return this.editedIndex2 === -1 ? "New Line" : "Edit Line";
    },
    /* Filters */
    dateRangeText() {
      return this.formatDate(this.rangeDates);
    },
    ...mapGetters([ 'currentUser' ])
  },

  props: [
    "headers", 
    "headers_extra", 
    "items", 
    "items_extra", 
    "items_extra_new", 
    "search_tool", 
    "current_wo_id", 
    "current_wo_vessel", 
    "current_wo_port"
  ],

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {

    getWOData() {
      var _self = this;
      genericService.getRecord('workorders', this.current_wo_id, function(record){
        _self.current_wo_vessel = record.vessel;
        _self.current_wo_port = record.port_label;
      });
    },

    editItem(item, dialog) {
      /* Listado de Dialogs */
      if(dialog == 'dialog'){
        this.formNewtItem = false;
        this.dialog = true;
        this.editedIndex = this.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
      }else if(dialog == 'dialog2'){
        this.dialog2 = true;
        this.editedIndex2 = this.items_extra.indexOf(item);
        this.editedItem = Object.assign({}, item);
      }else if(dialog == 'dialog3'){
        this.dialog3 = true;
        this.editedIndex2 = this.items_extra_new.indexOf(item);
        this.editedItem = Object.assign({}, item);
      }
      
    },

    seeItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      
      //window.open('http://localhost/simonsen_api/reports/pdf/proformas/'+item.id+'/', '_blank');
      //window.open('http://66.97.33.159/api/reports/pdf/proformas/'+item.id+'/', '_blank');
      window.open('http://crm.simonsen.com.ar/api/reports/pdf/proformas/'+item.id+'/', '_blank');
    },

    calcSubtotal(item){
      let subtotalResult = (item.unit_price - (item.discount * item.unit_price / 100)) * item.qty ;
      item.subtotal = subtotalResult;
      this.calcTotal();
      return subtotalResult.toFixed(2);
    },

    calcTotal(){
      this.totalAmount = this.items_extra.reduce((a, b) => a + b.subtotal, 0);
      return this.totalAmount;
    },

    deleteItem(item, list) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        const index = list.indexOf(item);
        if (result.value) {
          genericService.deleteItem(this.uri, item.id);
          list.splice(index, 1);
          Swal.fire({
            title: 'Deleted!',
            text: "Your record has been deleted.",
            icon: 'success'
          })
        }
      })
    },

    close() {
      /* Listado de Dialogs */
      this.dialog = false;
      this.dialog2 = false;

      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.editedIndex2 = -1;
      }, 300);
    },
    close2() {
      /* Listado de Dialogs */
      this.dialog3 = false;
      this.dialog4 = false;

      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.editedIndex2 = -1;
      }, 300);
    },

    save(items) {
      var _self = this;
      
      if(_self.editedItem.work_order == "" || 
        _self.editedItem.to == "" || 
        _self.editedItem.date == "") {
        Swal.fire({
            title: "",
            text: "Fields Work Order, To and Date are mandatories.",
            icon: "warning",
            confirmButtonClass: "btn btn-secondary"
          });
          return;
      }
      
      if (this.editedIndex > -1) {
        Object.assign(items[this.editedIndex], this.editedItem);
        genericService.updateRecord("/"+this.uri+"/" + this.editedItem.id, this.editedItem, function(){
          Swal.fire({
            title: "",
            text: "Record has been successfully saved!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          });
        });
      } else {
        this.editedItem.vessel = this.current_wo_vessel;
        this.editedItem.port = this.current_wo_port;
        this.editedItem.work_order  = this.current_wo_id;
        items.push(this.editedItem);
        genericService.createRecord("/"+this.uri, this.editedItem, function(rta){
          if(rta != undefined) {
            Swal.fire({
              title: "",
              text: "Record has been successfully saved!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary"
            });
            _self.editedItem.id = rta.id;
          }
        });

      }
      this.close();
    },

    save2(item) {
      if (this.editedIndex2 > -1) {
        Object.assign(item[this.editedIndex2], this.editedItem);
      } else {
        item.push(this.editedItem);
      }
      this.close();
    },

    reset(){
      this.$emit('initialize');
    },

    formatAmount(nr) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
      });

      return formatter.format(nr);
    },

    /* Filtros & Loading */
    currentItems(item) {
      this.loading = true;
      setTimeout(() => {
        this.totalTime = item.reduce((a, b) => a + (b["time"] || 0), 0);
        /* Filtros */
        this.itemsFiltered = item.length;
        this.loading = false;
      }, 300);
    },

    /* BOM - Filtros */
    clearFilterItems() {
      var _self = this;
      _self.rangeDates = [];
      _self.dateRangeText = "";
      _self.filterWorkOrder = "";
      
      genericService.getRecordListWithLimit('/'+this.uri, 10, "date", _self.currentUser.role,_self.currentUser.id,function(records){
         _self.items = records;
         _self.dialogFilters = false;
      });
    },

    filterItems() {
      var _self = this;
      var params = {
        "module": _self.uri,
        "range": _self.rangeDates,
        "wo": _self.filterWorkOrder
      }

      genericService.getRecordListWithParams(params, _self.currentUser.role, _self.currentUser.id, function(records){
         _self.items = records;
         _self.dialogFilters = false;
      });
    },

    formatDate(dates) {
      if (dates[1]) {
        const [year1, month1, day1] = dates[0].split("-");
        const [year2, month2, day2] = dates[1].split("-");
        this.date1 = `${day1}/${month1}/${year1}`;
        this.date2 = `${day2}/${month2}/${year2}`;

        this.formatedDates = this.date1 + " ~ " + this.date2;
      } else {
        this.formatedDates = "";
      }
      return this.formatedDates;
    },

    focusField(target) {
      setTimeout(() => {
        target.focus();
      }, 500);
    },
    /* EOM - Filtros */

    formNewItem(){
      this.formNewtItem = true;
      setTimeout(() => {
        this.focusField(this.$refs.formFirstField);
      }, 100)
    },

    newLine(item){
      this.expanded.push(item);
      this.dialog2=true;
    }
  },
  
  mounted() {
    var _self = this;

    if(this.$route.params.id == "new"){
      setTimeout(() => {
        this.dialog = true;
        this.formNewItem();
      }, 700);
    }else{
      if(this.search_tool == true){
        this.focusField(this.$refs.search);
      }
    }

    if(this.currentUser.role == "Admin") {
      genericService.getRecordList('/workorders/', function(records){
          _self.workOrdersList = records.reverse();
      });
    }
  }
};
</script>
